@import	"_kit/normalise", "_kit/resets-helpers", "_kit/glider"; // Standardise elements + libraries
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');
// Our variables
$base-font-family: 'Rubik',Helvetica, Arial, sans-serif;
$base-font-size:	2rem;
$base-font-weight:	300;

$heading-font: 'Rubik', 'Helvetica Bold', 'Arial Black', sans-serif;

$base-line-height:	1.4;

$base-text-color:	#666;
$spot-color: #002A84;
$muted-color: lighten(#e3e3e3, 5%);
$background-color: white;

$header-height: 6.5rem;
$content-width: 130rem;

$active: $spot-color;

html,
body {
	padding: 0;
	margin: 0;
	position: relative;
  height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	font-size: $base-font-size;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
	padding-top: $header-height;
}

a {
	color: $active;
	font-weight: 400;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
	font-weight: 300;
	line-height: 1em;
	padding-bottom: 0.35em;
	color: #222;

	& + p {
		margin-top: 0;
	}

}

.small-print {
	font-size: 1.4rem;
	color: #666;
	display: block;
	position: relative;

	&.bifa {
		display: block;
		max-width: 75rem;
		margin: 2.4rem auto 0 auto;
		background: $muted-color;
		padding: 1.6rem;
		min-height: 8rem;
		border-radius: 5px;
		text-align: center;

		@media only screen and (min-width:630px) {
			text-align: left;
			padding: 2.4rem;
			padding-left: calc(11.2rem + 2.4rem);
		}

		img {
			display: block;
			width: 10rem;
			margin: 0 auto;
			margin-bottom: 0.8rem;

			@media only screen and (min-width:630px) {
				position: absolute;
				top: 1.8rem;
				left: 2.4rem;
				margin-bottom: 0;
			}
		}

	}
}

h1, .h1 {
	font-size: 4.4rem;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background: rgba(255,255,255,0.9);
	z-index: 100;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

	.logo {
		background: $spot-color;
		display: block;

		svg {
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: 437px;
			height: 79px;

			rect {
				fill: $spot-color;
			}
		}
	}

	nav {
		display: inline-flex;
		justify-content: space-between;
		width: 100%;
		max-width: 45rem;
		padding: 0 1.6rem;

		a {
			display: inline-block;
			padding: 1.6rem 0;
			text-transform: uppercase;
			font-size: 1.3rem;
			font-weight: bold;
			letter-spacing: 1px;
		}

	}

}

section {
	padding: 10rem 1.6rem;
	position: relative;
}

.anchor {
	position: relative;
	display: block;
	top: -7.5rem;
	visibility: hidden;
}

.section {

	.wrapper {
		position: relative;
		z-index: 10;
	}

	&__hero, &__services, &__social {
		background: $muted-color;
	}

	&__hero {
		text-align: center;
		background: $muted-color url(https://res.cloudinary.com/squidge-inc/image/fetch/w_1200,f_auto,q_auto/e_contrast:100/e_saturation:-100/e_colorize:90,co_rgb:777777/https://thamefreight.co.uk/assets/hero-00.jpg) no-repeat bottom center;
		color: white;
		background-size: cover;
		background-position: bottom center;

		@media only screen and (min-width:961px) {
			background-position: bottom right;
		}

		.wrapper {
			display: flex;
			min-height: 70vh;
		}

		.content {
			margin: auto;
		}

		.opening {
			font-size: 2.4rem;
		}
	}

	&__about {

		img {
			border-radius: 5px;
		}

	}

	&__services {
		background: $muted-color url(https://res.cloudinary.com/squidge-inc/image/fetch/w_1200,f_auto,q_auto/e_contrast:100/e_saturation:-100/e_colorize:90,co_rgb:ffffff/e_gradient_fade,y_-0.5,b_rgb:f0f0f0/https://thamefreight.co.uk/assets/tf-services-00.jpg) no-repeat center -50px;
		background-size: 100% auto;
	}

	&__social {
		background: $muted-color url(https://res.cloudinary.com/squidge-inc/image/fetch/w_1200,f_auto,q_auto/e_contrast:100/e_saturation:-100/e_colorize:90,co_rgb:ffffff/e_gradient_fade,y_-0.5,b_rgb:f0f0f0/https://thamefreight.co.uk/assets/tf-social-02.jpg) no-repeat center -50px;
		background-size: 100% auto;

		p {
			max-width: 80rem;
			margin-left: auto;
			margin-right: auto;
		}

	}

}

.wrapper {
	width: 100%;
	max-width: $content-width;
	margin: 0 auto;
}

.cta {
	text-align: center;

	a {
		margin-top: 1.2rem;

		@media only screen and (min-width:630px) {
			margin-right: 0.8rem;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__left {
		text-align: left;
	}

}

.centralise {
	text-align: center;
}

.accordion {
	width: 100%;
	max-width: 80rem;
	margin: 0 auto;
	text-align: left;
	padding: 2.4rem 0;

	&__title {
		position: relative;
		background: $active;
		padding: 1.6rem 0.8rem;
		padding-left: 3.8rem;
		margin-bottom: 2px;
		cursor: pointer;
		border-radius: 5px;

		h2 {
			padding: 0;
			color: white;
			font-size: 2.4rem;
		}

		&.open {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.accordion__icon {

				&--closed {
					display: none;
				}

				&--open {
					display: block;
				}

			}

		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 0.8rem;
		margin-top: -12px;

		svg {
			width: 24px;
			height: 24px;

			path {
				fill: white;
			}
		}

		&--open {
			display: none;
		}
	}

	&__content {
		display: none;
		background: rgba(255,255,255,0.9);
		margin: 0;
		margin-bottom: 2px;
		padding: 2.4rem 2.4rem 2.4rem 3.8rem;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		&.open {
			display: block;
		}

		h3 {
			margin-top: 0.8rem;
		}

		p, ol, ul {
			margin: 0;
			margin-bottom: 1.6rem;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}
}

.vehicles {
	display: flex;
	flex-wrap: wrap;
	padding: 2.4rem 0;
	-ms-overflow-style: none !important;

	&::-webkit-scrollbar {
		display: none !important;
	}

	&-wrapper {
		position: relative;
	}

	&__prev, &__next {
		width: 48px;
		position: absolute;
		top: 2.4rem;
		bottom: 2.4rem;
		display: flex;
		align-items: center;
		justify-content: center;

		&.disabled {
			.disc {
				background: $muted-color;
			}
		}

		.disc {
			background: $spot-color;
			width: 38px;
			height: 38px;
			display: flex;
			border-radius: 50%;
			border: 6px solid white;
			cursor: pointer;
		}

		svg {
			width: 24px;
			height: 24px;
			margin: auto;

			path {
				fill: white;
			}
		}
	}

	&__prev {
		left: -1.6rem;

		.disc {
			margin-left: -0.8rem;
		}

	}

	&__next {
		right: -1.6rem;

		.disc {
			margin-right: -0.8rem;
		}

	}
}

.glider-dot.active {
	background: $spot-color;
}

.vehicle {
	text-align: left;
	width: 100%;
	padding: 0.8rem;

	@media only screen and (min-width:655px) {
		width: 50%;
	}

	@media only screen and (min-width:961px) {
		width: 33.33%;
	}

	@media only screen and (min-width:1261px) {
		width: 25%;
	}

	h2 {
		font-size: 2.2rem;
	}

	li {
		font-size: 1.4rem;
		padding-bottom: 0.8rem;
		strong {
			font-size: 1.6rem;
			font-weight: 400;
			color: #222;
		}
	}

	&__inner {
		background: $muted-color;
		padding: 1.6rem;
		border-left: 4px solid darken($muted-color, 25%);
	}
}

.split {

	.image {
		padding-bottom: 2.4rem;
		max-width: 50rem;
		margin: 0 auto;
	}

	img {
		width: 100%;
	}

	@media only screen and (min-width:961px) {
		display: flex;
		flex-wrap: wrap;

		.image {
			max-width: 100%;
			width: 50%;
			padding-right: 1.2rem;
		}

		.content {
			width: 50%;
			padding-left: 1.2rem;
		}

	}


}

.instagram {

	&-gallery {
		position: relative;
		z-index: 10;
		display: flex;
		flex-wrap: wrap;

		&__item {
			width: 50%;
			padding: 0.8rem;
			opacity: 1;
			transition: all 0.3s;

			&:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8){
				display: none;
			}

			@media only screen and (min-width:630px) {
				width: 25%;

				&:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7), &:nth-of-type(8){
					display: block;
				}
			}

			img {
				width: 100%;
				display: block;
				border-radius: 5px;
			}

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&-placeholder {
		margin: 2.4rem auto 0 auto;
		background: $muted-color;
		padding: 2.4rem;
		border-radius: 5px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

		svg {
			width: 48px;
			path {
				fill: #999;
			}
		}
	}
}

.contact {

	@media only screen and (min-width:900px) {
		display: flex;
		align-items: flex-start;
	}

	&__form {
		padding: 2.4rem 0;

		@media only screen and (min-width:900px) {
			width: 66.66%;
			padding-right: 2.4rem;
		}

		input[type=submit]{
			margin-top: 1.6rem;
		}
	}

	&__additional {
		position: relative;
		margin-top: 2.4rem;
		width: 100%;
		background: $muted-color;
		border-left: 4px solid darken($muted-color, 25%);
		font-size: 1.6rem;
		padding: 2.4rem;

		@media only screen and (min-width:665px) {
			padding-right: calc(35rem + 2.4rem + 2.4rem);
		}

		@media only screen and (min-width:900px) {
			width: 33.33%;
			padding-right: 2.4rem !important;
		}



		h2 {
			font-size: 2.2rem;
		}
	}

	&__map {
		width: 100%;

		@media only screen and (min-width:665px) {
			position: absolute;
			top: 2.4rem;
			right: 2.4rem;
			max-width: 35rem;
		}

		@media only screen and (min-width:900px) {
			position: relative !important;
			top: auto !important;
			right: auto !important;
			max-width: 100% !important;
		}


	}
}

.fancy-link {
	padding: 0.8rem;
	border-radius: 3rem;
	display: inline-flex;
	align-items: center;
	margin-bottom: 0.8rem;
	transition: all 0.3s;
	opacity: 1;
	transition: all 0.3s;

	&__alt {

		.fancy-link {

			&__icon {
				background: $spot-color;

				svg {
					path {
						fill: white;
					}
				}
			}

			&__text {
				color: $spot-color;
			}

		}
	}

	&__icon {
		background: white;
		width: 3.6rem;
		height: 3.6rem;
		border-radius: 3.2rem;
		display: flex;
		margin-right: 0.8rem;

		svg {
			margin: auto;
			width: 16px;
			height: 16px;
			path {
				fill: $spot-color;
			}
		}
	}

	&__text {
		font-size: 1.6rem;
		color: white;
	}

	&:hover {
		opacity: 0.75;
	}
}

.button {
	display: inline-block;
	background: $spot-color;
	border-radius: 3px;
	border: 0;
	color: white;
	padding: 1.6rem;
	min-width: 28rem;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: bold;
	letter-spacing: 1px;
	opacity: 1;
	transition: all 0.3s;

	@media only screen and (min-width:630px) {
		min-width: 20rem;
	}

	&__flip {
		background: white;
		color: $spot-color;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}

	&:hover {
		box-shadow: 0;
		opacity: 0.85;
	}
}

.form {

	&__row {

		@media only screen and (min-width:630px) {
			display: flex;
		}

		.form__item {
			position: relative;
			padding-bottom: 2.4rem;

			@media only screen and (min-width:630px) {
				&:first-of-type {
					padding-right: 1.2rem;
				}
				&:last-of-type {
					padding-left: 1.2rem;

					svg {
						left: calc(1.2rem + 0.8rem);
					}
				}
			}

			svg {
				position: absolute;
				bottom: 3.5rem;
				left: 0.8rem;
				width: 24px;
				height: 24px;

				path {
					transition: all 0.3s;
					fill: darken($muted-color, 10%);
				}

			}
		}
	}

	&__item {
		font-size: 1.6rem;
		display: inline-block;
		width: 100%;
	}

	&__input {
		position: relative;
		display: block;
		font-size: 2rem;
		width: 100%;
		padding: 0.8rem;
		padding-left: 3.6rem;
		border: 1px solid darken($muted-color, 10%);
		transition: all 0.3s;

		&:focus {
			border-color: $spot-color;

			& + svg {
				path {
					fill: $spot-color;
				}
			}
		}
	}

}

textarea {
	padding-left: 0.8rem !important;
	min-height: 298px;
}

.global-footer {
	padding: 2.4rem 0;
	background: $muted-color;

	span, a {
		display: inline-block;
	}
}

// Toaster
.toaster {
  display: block;
  background: seagreen;
  z-index:9999;
  position:fixed;
  top:-100%;
  width:100%;
  padding: 2.4rem 1.6rem;
  color: white;
  text-align:center;
  opacity: 0;
  transition: all 0.5s;
	font-size: 1.6rem;
	font-weight: 400;

  &.open {
    opacity: 1;
    top: 0;
  }

  &__content {
    display:block;
  }

  &.failed {
    background: tomato;
    color: white;
  }

}
